import { Loading } from '@shopify/polaris'
import { lazy, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'

import { useCommonData } from 'common/useCommonData'
import { DispositionType, ExchangeOrderStatus, useRmaQuery } from 'gql'
import { notEmpty } from 'utils'

import { handleData, resetRMAState } from './slice/rma.slice'

const RMAPage = lazy(() => import('./RMAPage'))

export const RMALoader = () => {
  const params = useParams()
  const dispatch = useAppDispatch()

  const returnId = parseInt(params.id!)
  useEffect(() => {
    dispatch(resetRMAState())
  }, [returnId])
  const [rmaState] = useCommonData(useRmaQuery, {
    variables: { returnId },
  })
  useEffect(() => {
    const { data, error } = rmaState

    if (error) console.error(error)
    if (!data?.getReturn?.items) return

    dispatch(
      handleData({
        rma: {
          ...data.getReturn,
          items: data.getReturn.items.filter(notEmpty).map((item) => {
            const storeCredit = data.getReturn!.storeCredits?.find((sc) =>
              sc?.returnItems.find((i) => i.id === item.id),
            )
            return {
              ...item,
              isResolvedSomehow: !!(
                item.isResolvedManually ||
                item.isRefunded ||
                item.exchangeOrder?.status === ExchangeOrderStatus.Completed ||
                storeCredit
              ),
              isRestocked: item.dispositionType === DispositionType.Restocked,
              isExpired: !!item.expiredAt,
              storeCredit,
              dispositionType: undefined,
            }
          }),
        },
      }),
    )
  }, [rmaState.data, rmaState.error])
  return (
    <>
      {(rmaState.fetching || rmaState.stale) && <Loading />}
      <RMAPage />
    </>
  )
}

export default RMALoader
