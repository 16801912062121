import { AuthenticatedShop } from 'gql'

export * from './hooks'

// Handy way to strip all the errors caused by <Maybe> generated types
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const isEmpty = (value: string | Array<string | number | null> | null) => {
  if (Array.isArray(value)) {
    return value.length === 0
  } else {
    return value === '' || value === null
  }
}

interface StageChoice {
  value: string
  label: string
}

export const getStages = (shop: AuthenticatedShop, includeArchived = false) => {
  const stages: StageChoice[] = []
  if (shop.stages) {
    shop.stages.forEach((s) => {
      if (s) {
        if (!includeArchived && s.archived) {
          return
        }
        stages.push({
          value: s.id,
          // value: s.value,
          label: s.label,
        })
      }
    })
  }
  return stages
}

export const getStagesMap = (shop: AuthenticatedShop) => {
  const stages: { [key: string]: string } = {}
  shop.stages?.filter(notEmpty).forEach((s) => (stages[s.id] = s.label))
  return stages
}

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const toNumber = (s: string) => parseInt(s, 10)
export const toString = (n: number) => n.toString()

export const openExternalUrl = (url: string) => {
  // https://stackoverflow.com/questions/4907843/
  window.open(url, '_blank')?.focus()
}

// Shorten a string to less than maxLength characters without truncating words.
// https://stackoverflow.com/a/40382963/4915
export function shortenSentence(str: string, maxLength: number, separator = ' ') {
  if (str.length <= maxLength) return str
  return str.substr(0, str.lastIndexOf(separator, maxLength))
}

export function compareMaps<T, U>(map1: Map<T, U>, map2: Map<T, U>) {
  if (map1.size !== map2.size) return false
  for (const [key, val] of map1) {
    if (!map2.has(key)) return false
    if (map2.get(key) !== val) return false
  }
  return true
}

export const isNumber = (value: unknown) => {
  if (typeof value === 'boolean') return false
  if (typeof value === 'object') return false
  if (typeof value === 'undefined') return false
  if (typeof value === 'string' && value.trim() === '') return false
  return !isNaN(value as any)
}

export const areSetsEqual = (a: Set<unknown>, b: Set<unknown>) =>
  a.size === b.size && [...a].every((value) => b.has(value))

export const roundCents = <T,>(value: T) => {
  if (typeof value === 'number') return Math.round(value * 100) / 100
  else return value
}

export const isElementInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect()
  return rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
}
