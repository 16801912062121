import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Mode, RMA } from 'rma/models'
import { selectShopSystemStages } from 'store/global/global.selectors'
import { AppThunk, loadSlice } from 'store/store'

import { ExchangeOrderStatus, ReturnType, SystemStage, WarehousesQuery } from 'gql'

import { selectQuickHighlightGroup } from './rma.selectors'

type Warehouses = Exclude<WarehousesQuery['warehouses'], null | undefined>
export type Warehouse = Exclude<Warehouses[number], null | undefined>

export interface RMASlice {
  refundEnabled: boolean
  exchangeEnabled: boolean
  creditEnabled: boolean
  restockEnabled: boolean
  mode: Mode
  stageUpdatingTo: string | null
  selectionEnabled: boolean
  selected: string[]
  showTestReturnBanner: boolean
  showDeleteModal: boolean
  gallery: {
    id: string | null
    index: number
  }
  rma?: RMA
  quickHighlightGroup: string | null
}

const initialState: RMASlice = {
  refundEnabled: true,
  exchangeEnabled: false,
  creditEnabled: false,
  restockEnabled: true,
  mode: 'resting',
  stageUpdatingTo: null,
  showTestReturnBanner: true,
  showDeleteModal: false,
  selectionEnabled: false,
  selected: [],
  gallery: {
    id: null,
    index: 0,
  },
  quickHighlightGroup: null,
}

const rmaSlice = createSlice({
  name: 'rma',
  initialState,
  reducers: {
    resetRMAState(state: RMASlice) {
      state.mode = initialState.mode
      state.stageUpdatingTo = initialState.stageUpdatingTo
      state.showTestReturnBanner = initialState.showTestReturnBanner
      state.showDeleteModal = initialState.showDeleteModal
      state.selectionEnabled = initialState.selectionEnabled
      state.selected = initialState.selected
      state.gallery = initialState.gallery
      state.rma = undefined
    },
    handleData(state: RMASlice, { payload: { rma } }: PayloadAction<{ rma: RMASlice['rma'] }>) {
      state.rma = rma
      if (state.mode === 'exchange') {
        const completedExchanges = rma?.items
          .filter((i) => i.exchangeOrder?.status === ExchangeOrderStatus.Completed)
          .map((i) => i.id)
        if (completedExchanges) state.selected = state.selected.filter((id) => !completedExchanges.includes(id))
      }
    },
    dismissBanner(state: RMASlice) {
      state.showTestReturnBanner = false
    },
    openDeleteModal(state: RMASlice) {
      state.showDeleteModal = true
    },
    closeDeleteModal(state: RMASlice) {
      state.showDeleteModal = false
    },
    setMode(state: RMASlice, { payload: mode }: PayloadAction<RMASlice['mode']>) {
      state.mode = mode
    },
    setStageUpdatingTo(state: RMASlice, { payload: stageId }: PayloadAction<string>) {
      state.stageUpdatingTo = stageId
    },
    setSelectionEnabled(state: RMASlice, { payload: enabled }: PayloadAction<boolean>) {
      state.selectionEnabled = enabled
    },
    selectAllItems(state: RMASlice) {
      state.selected = state.rma?.items.map((i) => i.id) || []
    },
    selectRefundable(state: RMASlice) {
      state.selected =
        state.rma?.items
          .filter((i) => !i.isMissing && !i.isExpired && !i.isResolvedSomehow && i.returnType === ReturnType.Refund)
          .map((i) => i.id) || []
    },
    selectExchangeModeItems(state: RMASlice) {
      state.selected =
        state.rma?.items
          .filter(
            (i) =>
              !i.isMissing &&
              !i.isExpired &&
              !i.isResolvedSomehow &&
              i.returnType === ReturnType.Exchange &&
              i.exchangeOrder,
          )
          .map((i) => i.id) || []
    },
    selectCreditable(state: RMASlice) {
      state.selected =
        state.rma?.items
          .filter((i) => !i.isMissing && !i.isExpired && !i.isResolvedSomehow && i.returnType === ReturnType.Credit)
          .map((i) => i.id) || []
    },
    selectResolvable(state: RMASlice) {
      state.selected =
        state.rma?.items
          .filter(
            (i) =>
              !i.isMissing &&
              !i.isExpired &&
              !i.isResolvedSomehow &&
              ((i.returnType === ReturnType.Refund && !state.refundEnabled) ||
                (i.returnType === ReturnType.Exchange && !state.exchangeEnabled) ||
                (i.returnType === ReturnType.Credit && !state.creditEnabled)),
          )
          .map((i) => i.id) || []
    },
    selectRestockable(state: RMASlice) {
      state.selected = state.rma?.items.filter((i) => !i.isMissing && !i.isRestocked).map((i) => i.id) || []
    },
    toggleItemsSelected(state: RMASlice, { payload: ids }: PayloadAction<string[]>) {
      const selectedCount = ids.filter((id) => state.selected.includes(id)).length
      if (selectedCount < ids.length) {
        for (const id of ids) if (!state.selected.includes(id)) state.selected.push(id)
      } else {
        for (const id of ids) if (state.selected.includes(id)) state.selected.splice(state.selected.indexOf(id), 1)
      }
    },
    setItemsSelected(
      state: RMASlice,
      { payload: { ids, selected } }: PayloadAction<{ ids: string[]; selected: boolean }>,
    ) {
      if (selected) {
        for (const id of ids) if (!state.selected.includes(id)) state.selected.push(id)
      } else {
        for (const id of ids) if (state.selected.includes(id)) state.selected.splice(state.selected.indexOf(id), 1)
      }
    },
    openGallery(state: RMASlice, { payload: { id, startIndex } }: PayloadAction<{ id: string; startIndex?: number }>) {
      state.gallery.id = id
      state.gallery.index = startIndex || 0
    },
    galleryNext(state: RMASlice) {
      const images = state.rma?.items.find((i) => i.id === state.gallery.id)?.images
      if (images) state.gallery.index = (state.gallery.index + 1) % images.length
    },
    galleryPrevious(state: RMASlice) {
      const images = state.rma?.items.find((i) => i.id === state.gallery.id)?.images
      if (images) state.gallery.index = (state.gallery.index - 1 + images.length) % images.length
    },
    closeGallery(state: RMASlice) {
      state.gallery.id = null
    },
    setQuickHighlightGroup(state: RMASlice, { payload: newValue }: PayloadAction<string | null>) {
      state.quickHighlightGroup = newValue
    },
  },
})

export const {
  resetRMAState,
  handleData,
  dismissBanner,
  openDeleteModal,
  closeDeleteModal,
  setMode,
  setStageUpdatingTo,
  setSelectionEnabled,
  selectAllItems,
  selectRefundable,
  selectExchangeModeItems,
  selectCreditable,
  selectResolvable,
  selectRestockable,
  openGallery,
  galleryNext,
  galleryPrevious,
  closeGallery,
  toggleItemsSelected,
  setItemsSelected,
  setQuickHighlightGroup,
} = rmaSlice.actions

export const updateMode =
  (mode: RMASlice['mode']): AppThunk =>
  (dispatch) => {
    dispatch(setMode(mode))

    if (mode === 'refund') dispatch(selectRefundable())
    if (mode === 'exchange') dispatch(selectExchangeModeItems())
    if (mode === 'credit') dispatch(selectCreditable())
    if (mode === 'resolve') dispatch(selectResolvable())
    if (mode === 'restock') dispatch(selectRestockable())

    dispatch(
      setSelectionEnabled(
        mode === 'refund' || mode === 'exchange' || mode === 'credit' || mode == 'resolve' || mode === 'restock',
      ),
    )
  }

export const updateToStage =
  (stageId: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState()
    const systemStages = selectShopSystemStages(state)
    const systemStage = systemStages?.find((ss) => ss.id === stageId)?.systemStage
    dispatch(updateMode('update-to-stage'))
    dispatch(setStageUpdatingTo(stageId))
    if (systemStage === SystemStage.Received) {
      dispatch(setSelectionEnabled(true))
      dispatch(selectAllItems())
    }
  }

let quickHighlightTimeout: number | undefined

export const quicklyHighlightGroup =
  (groupId: string): AppThunk =>
  (dispatch, getState) => {
    clearTimeout(quickHighlightTimeout)
    dispatch(setQuickHighlightGroup(null))
    quickHighlightTimeout = window.setTimeout(() => {
      dispatch(setQuickHighlightGroup(groupId))
      quickHighlightTimeout = window.setTimeout(() => {
        if (selectQuickHighlightGroup(getState()) === groupId) dispatch(setQuickHighlightGroup(null))
      }, 2500)
    })
  }

loadSlice(rmaSlice)
